.withoutInput {
  display: flex !important;
  align-items: baseline;
}
.headText {
  padding-right: 8px;
}
.react-calendar__year-view {
  display: none !important;
}
.react-calendar__navigation {
  display: flex;
}
.yearPicker .react-calendar__navigation__label,
.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  border: none;
  cursor: pointer;
}
.react-calendar__navigation__label {
  flex-grow: 1 !important;
}
.react-calendar__navigation__label__labelText {
  font-size: 1rem;
  font-family: "pc_semibold" !important;
  font-size: 19px;
}
.yearPic_container {
  border-radius: 5px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
  font-family: Poppins;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
}
.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  font-size: 1.6rem;
  width: 50px;
}
.calendarLabel {
  font-family: "pc_semibold" !important;
  color: #6f6f6f !important;
  font-size: 16px !important;
  padding-right: 10px;
}
.arrow_go {
  border: 1px solid #6f6f6f;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  cursor: pointer;
}
.nextBtn {
  border: none;
  background: none;
}
.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.padding {
  padding-right: 11px;
  padding-top: 3px;
  padding-left: 11px;
}
.normalYear {
  display: flex;
}
.currentYear {
  font-family: "pc_semibold" !important;
  color: #000 !important;
  font-size: 16px !important;
  padding-right: 10px;
}
