.statusGrid {
  justify-content: center;
  width: 84px;
  height: 32px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.statusText {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.point {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  margin: 3px 5px 0px 0px;
}
.active {
  padding-top: 2px;
  color: #3bb213;
  font-family: "pc_semibold" !important;
  text-transform: none !important;
}
.cancel {
  padding-top: 2px;
  color: #b6b6b6;
  font-family: "pc_semibold" !important;
}
.inActive {
  padding-top: 2px;
  color: #ec6a49;
  font-family: "pc_semibold" !important;
  text-transform: none !important;
}
