.rtf-wrapper {
    display: flex;
    flex-direction: column;
    border: 2px solid #d3d3d3;
    border-radius: 8px;
    max-height: 60px;
  }

.rtf-wrapper-body {
    /* display: flex; */
    /* flex-direction: column; */
    border: 2px solid black;
    border-radius: 8px;
    min-height: 280px;
    max-height: 400px;
    overflow: scroll;
    /* justify-content: space-between; */
  }
  
  .rtf-wrapper-with-direction {
    display: flex;
    flex-direction: column;
    border: 2px solid #d3d3d3;
    max-height: 60px;
    border-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .rtf-wrapper-with-direction-body {
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-between; */
    border: 2px solid #d3d3d3;
    min-height: 400px;
    max-height: 400px;
    border-radius: 8px;
    overflow: scroll;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  
  .rtf-toolbar {
    order: 2;
    padding: 12px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: 1px solid #dee5ec;
    margin-bottom: 0px;
    gap: 8px;
    /* height: 20px; */
  }
  
  .rtf-toolbar-with-direction-control {
    order: 2;
    padding: 12px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top: 1px solid #dee5ec;
    margin-bottom: 0px;
    gap: 8px;
    /* height: 20px; */
  }
  
  .rtf-editor {
    padding: 3px;
    border-radius: 8px;
  }
  
  .rtf-toolbar-inline,
  .rtf-toolbar-list {
    margin: 0px;
    gap: 8px;
    position: absolute;
    top:0
  }
  
  .rtf-toolbar-inline .rdw-option-wrapper,
  .rtf-toolbar-list .rdw-option-wrapper {
    border-radius: 2px;
    border: 0px;
    padding: 2px 0px;
    margin: 0px;
    min-width: 25px;
  }
  
  .rtf-toolbar-inline .rdw-option-wrapper:hover,
  .rtf-toolbar-list .rdw-option-wrapper:hover {
    box-shadow: none;
    background-color: #dee5ec;
  }
  
  .rtf-toolbar-inline .rdw-option-active,
  .rtf-toolbar-list .rdw-option-active {
    background-color: #dee5ec;
    box-shadow: none;
  }
  