.statusGrid {
  justify-content: center;
  width: 84px;
  height: 32px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.statusText {
  display: flex;
  cursor: pointer;
  align-items: center;
  /* background-color: green; */
  padding: 2px;
}
/* .statusText:hover {
  background-color: green;
} */

.activePoint {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  margin: 3px 5px 0px 0px;
  background-color: #37b652;
}
.inActivePoint {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  margin: 3px 5px 0px 0px;
  background-color: #ec6a49;
}
.active {
  align-items: center;
  font-size: 14px;
  align-items: center;
  color: #37b652;
  text-transform: none !important;
}
.status {
  align-items: center;
  font-size: 12px;
  align-items: center;
  color: #37b652;
  /* padding-top: 2px; */
  font-family: "pc_semibold" !important;
  /* background-color: rgb(100, 220, 100); */
  border-radius: 20px;
  padding: 5px 15px 5px 5px;
  /* padding: 20px; */
}
.inActive {
  align-items: center;
  font-size: 14px;
  align-items: center;
  color: #ec6a49;
  text-transform: none !important;
  /* padding-top: 2px; */
  font-family: "pc_semibold" !important;
  /* background-color: rgb(100, 220, 100); */
  border-radius: 20px;
  padding: 5px 15px 5px 5px;
}
