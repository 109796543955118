.root {
  display: "flex";
}

.reasonHead {
  padding-top: 4px;
  display: flex;
  justify-content: space-between;
}

.tooltipHead {
  color: #000;
  font-size: 13px !important;
  font-family: pc_semibold !important;
  padding-bottom: 8px;
  font-weight: 500;
  padding-top: 8px;
}

.toolEdit {
  padding-right: 15px;
  cursor: pointer;
}

.toolIcon {
  filter: invert(23%) sepia(0%) saturate(1296%) hue-rotate(271deg)
    brightness(14%) contrast(2%) !important;
  padding-right: 10px;
  cursor: pointer;
}

/* .statusDiv span {
  padding-right: 5px;
} */
.cancelPic {
  width: 10px;
  filter: invert(13%) sepia(74%) saturate(7486%) hue-rotate(359deg)
    brightness(100%) contrast(109%);
}

.divCancel {
  padding: 1px 15px 4px 4px;
  background: #ff000014 !important;
  border-radius: 12px;
  margin-right: 10px;
  cursor: pointer;
  width: 18px;
  height: 20px;
}

.divCheck {
  padding: 1px 15px 4px 4px;
  background: #2a60bc;
  border-radius: 12px;
  margin-right: 10px;
  cursor: pointer;
  width: 18px;
  height: 20px;
}

.actionHead {
  padding-top: 5px;
  color: #6f6f6f !important;
  font-size: 13px !important;
  font-family: pc_regular !important;
  padding: 8px 0px;
}

.selectDiv {
  padding-top: 20px;
}

.selectDiv .MuiOutlinedInput-root {
  border-radius: 8px !important;
}

.selectDiv .MuiTypography-root {
  font-size: 13px !important;
}

.activeButton {
  margin-right: 10px;
  color: white !important;
  padding: 7px 19px !important;
  text-transform: none !important;
  font-size: 14px !important;
  font-family: pc_semibold !important;
  background: #3bb213 !important;
  border-radius: 7px !important;
}

.inActiveButton {
  margin-right: 10px;
  color: #ec6a49 !important;
  padding: 7px 19px !important;
  text-transform: none !important;
  font-size: 14px !important;
  font-family: pc_semibold !important;
  background: #ff000014 !important;
  border-radius: 7px !important;
}

.cancel {
  margin-right: 10px;
  color: #000 !important;
  padding: 7px 19px !important;
  text-transform: none !important;
  font-size: 14px !important;
  font-family: pc_semibold !important;
  background: #b6b6b6 !important;
  border-radius: 7px !important;
}
