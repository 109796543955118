/*  */

.css-1i0la1m-MuiTreeView-root {
  min-width: 100% !important;
}

/* .css-1ex1afd-MuiTableCell-root{
    width: 10%!important;
} */
.statusHeader {
  padding: 20px;
}

.MuiTreeItem-label {
  display: flex !important;
}

.css-tbgmt6-MuiTreeItem-content {
  margin-right: 30px !important;
}

.entityDiv {
  width: 640px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 18px;
  padding-bottom: 15px;
  font-family: "pc_semibold" !important;
  font-size: 14px;
}

.effectiveFromDiv {
  width: 220px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: "pc_semibold" !important;
  font-size: 14px;
  color: #001c3c;
}

.effectiveToDiv {
  width: 220px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: "pc_semibold" !important;
  font-size: 14px;
  color: #001c3c;
}
.reasonDiv {
  width: 410px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: "pc_semibold" !important;
  font-size: 14px;
  color: #001c3c;
}

.statusDiv {
  width: 100px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: "pc_semibold" !important;
  font-size: 14px;
  color: #001c3c;
}

.MuiCollapse-root .MuiCollapse-entered .entityDiv {
  font-family: "pc_semibold" !important;
  color: #2a3c50 !important;
}

.MuiCollapse-root .MuiCollapse-entered .effectiveFromDiv {
  font-family: "pc_semibold" !important;
  color: #2a3c50 !important;
}

.MuiCollapse-root .MuiCollapse-entered .effectiveToDiv {
  font-family: "pc_semibold" !important;
  color: #2a3c50 !important;
}

.MuiCollapse-root .MuiCollapse-entered .reasonDiv {
  font-family: "pc_semibold" !important;
  color: #2a3c50 !important;
}

.MuiCollapse-root .MuiCollapse-entered .statusDiv {
  font-family: "pc_semibold" !important;
  color: #2a3c50 !important;
}

.MuiCollapse-entered {
  margin-left: 0px !important;
}
/* .Effective{
   border: none;
   border: 1px solid rgb(162, 126, 201);
   padding: 5px;
} */

.effectiveto {
  padding-left: 60px !important;
}

.reason {
  padding-left: 42px !important;
}

.status {
  padding-left: 1px !important;
}

.toolDiv {
  padding-top: 14px;
}
.orgToolDiv {
  margin-left: -32px !important;
  padding-top: 14px;
}
/* .css-tbgmt6-MuiTreeItem-content .MuiTreeItem-iconContainer{
    margin-top: -25px!important;
} */
.MuiTreeView-root {
  font-size: 20px !important;
  height: 348 !important;
  overflow: auto !important;
}

.MuiTreeItem-root .Mui-expanded ::-webkit-scrollbar {
  display: none !important;
}

.MuiCollapse-wrapperInner .MuiTreeItem-iconContainer {
  margin-left: 15px !important;
}

.MuiCollapse-wrapperInner .effectiveFromDiv {
  margin-left: -15px !important;
}

.css-vj1n65-MuiGrid-root {
  align-items: center !important;
}

.entityTitle {
  margin-left: -20px !important;
}

.effectivefrom {
  padding-left: 60px !important;
}

.treetableBodyScroll {
  height: 350px;
  overflow: auto;
}

.treetableBodyScroll::-webkit-scrollbar {
  display: none !important;
}

.treeViewData {
  font-family: "pc_semibold" !important;
}

.treeTableHead {
  font-family: "pc_regular" !important;
  color: "#6F6F6F" !important;
  font-size: "12px" !important;
  padding-bottom: "8px";
}
.MuiCollapse-wrapperInner {
  padding-left: 30px !important;
}
.itemFacility {
  padding-left: 30px !important;
}
