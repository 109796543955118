/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2020-11-27
 * @modify date 2021-02-03
 * @desc All global CSS are defined here
 */
@font-face {
  font-family: "pc_semibold";
  src: url("./font/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "pc_extrabold";
  src: url("./font/Poppins-ExtraBold.ttf");
}
@font-face {
  font-family: "pc_regular";
  src: url("./font/Poppins-Regular.ttf");
}

@font-face {
  font-family: "pc_medium";
  src: url("./font/Poppins-Medium.ttf");
}

@font-face {
  font-family: "pc_light";
  src: url("./font/Poppins-Light.ttf");
}
@font-face {
  font-family: "manage_care_semibold";
  src: url("./font/proxima_nova_semibold.woff") format("woff");
}
@font-face {
  font-family: "manage_care_bold";
  src: url("./font/proxima_nova_bold.woff") format("woff");
}

::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
} /* this targets the default scrollbar (compulsory) */

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.23) !important;
  border-radius: 24px;
  transition: all 0.5s;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(146, 151, 165, 1) !important;
}
/* this will style the thumb, ignoring the track */

::-webkit-scrollbar-button {
  background-color: transparent !important;
} /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

::-webkit-scrollbar-corner {
  background-color: transparent !important;
} /* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */

body {
  scrollbar-face-color: #f1f1f1 !important;
  height: 100%;
  background-color: #f6f6f6 !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiPickersDateRangePickerInput-root {
  width: 100%;
}
.fc-daygrid-event {
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
}
/* to hide popover */
.fc-more-popover {
  visibility: hidden;
}
.demo-app {
  padding: 20px;
}
.fc-timegrid-slot {
  height: 6rem !important;
}

.fc-button-active {
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 1px solid #dddfe1 !important;
}
.fc-button-primary {
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 1px solid #dddfe1 !important;
}
/* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_9785">Text with this font applied</p> */
body {
  font-family: "manage_care_semibold" !important;
}
code {
  font-family: "manage_care_semibold" !important;
}
textarea:focus,
input:focus {
  font-family: "manage_care_semibold" !important;
}
input,
select,
textarea {
  font-family: "manage_care_semibold" !important;
}

:fullscreen {
  z-index: 5;
}

html {
  height: 100%;
}
#root {
  height: 100%;
}

.without::-webkit-datetime-edit-ampm-field {
  display: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.wdr-credits a {
  display: none;
}

#wdr-pivot-view #wdr-data-sheet .wdr-row .wdr-cell {
  text-align: right !important;
}

/* React Date Picker Custom CSS */

.react-datepicker{
  width: 100% !important;
border: 1px solid #E9ECF2;
border-radius: 8px;
}
.react-datepicker__month-container{
  /* float: none !important; */
}
.react-datepicker-time__header{
  display: none;
}
.react-datepicker__header{
  background-color: #fff !important;
  padding-top:28px !important;
  border-bottom: none !important;
}
.react-datepicker__navigation-icon--previous::before{
  border-color: black !important;
  border-width: 1px 1px 0 0 !important;
}
.react-datepicker__navigation-icon--next::before{
  border-color: black !important;
  border-width: 1px 1px 0 0 !important;
}
.react-datepicker__navigation{
  top:25px !important;
}
.react-datepicker__navigation--previous{
  left:15px !important;
}
.react-datepicker__navigation--next{
  /* right:15px !important; */
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
  font-family: "pc_regular" !important;
  font-size: 14px !important;
}
.react-datepicker__header__dropdown{
  padding: 10px 12px !important;
  display: flex !important;
}
.react-datepicker__month-select{
  background-color: #DEE5EC;
  padding: 7px 6px 7px;
  width: 100%;
  border-radius: 6px;
  border:none;
}
.react-datepicker__month-dropdown-container{
  width: 100% !important;
}
.react-datepicker__year-dropdown-container{
  width: 60% !important;
}
.react-datepicker__year-select{
  background-color: #DEE5EC;
  padding: 7px 6px 7px;
  width: 100%;
  border-radius: 6px;
  border:none;
}
.react-datepicker__day-names, .react-datepicker__week{
  display: flex;
  justify-content: space-evenly;
}
.react-datepicker__day--selected, .react-datepicker__time--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
  background-color: #2A60BC !important;
  border-radius: 50% !important;
}
.react-datepicker__day--keyboard-selected{
  background-color: #2A60BC !important;
  border-radius: 50% !important;
}
.react-datepicker__day-name{
  color:#b6c1cb !important;
}
.react-datepicker__day--outside-month {
  color: #B6C1CB !important;
  pointer-events: none;
}

.react-datepicker-popper{
border: 1px solid #E9ECF2;
border-radius: 8px;
}

.react-datepicker__input-container {
/* height: 68px */
}